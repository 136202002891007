<template>
    <el-dialog
        :title="$t('cars.edit_car')"
        :visible.sync="visible"
        width="700px"
        top="40px"
        :close-on-click-modal="false"
        :before-close="closeModal"
        @open="getDetails"
    >
        <el-form ref="form" v-loading="$wait.is('loading.edit_car')" :model="formData" :rules="rules" class="px-16">
            <el-form-item :label="$t('cars.name')" prop="name">
                <el-input v-model="formData.name" />
            </el-form-item>
            <el-form-item :label="$t('cars.license_plate')" prop="license_plate">
                <el-input v-model="formData.license_plate" />
            </el-form-item>
            <el-form-item :label="$t('cars.odometer')" prop="odometer">
                <el-input v-model.number="formData.odometer" />
            </el-form-item>
            <el-form-item :label="$t('cars.production_year')" prop="production_year">
                <el-input v-model.number="formData.production_year" />
            </el-form-item>
            <el-form-item :label="$t('cars.holder')">
                <EmployeesDropdown
                    :withArchive="false"
                    :multiple="false"
                    :customClass="'w-full mt-3'"
                    :preSelectedEmployee="formData.holder_uuid"
                    @selected="formData.holder_uuid = $event"
                />
            </el-form-item>
            <el-form-item :label="$t('cars.note')" prop="note">
                <el-input v-model="formData.note" type="textarea" :rows="6" resize="none" />
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('loading.edit_car')" @click="edit">{{ $t('common.edit') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    components: {
        EmployeesDropdown: () => import(/* webpackChunkName: "EmployeesDropdown" */ '@/components/EmployeesDropdown'),
    },

    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
        selectedCarId: {
            type:    Number,
            default: null,
        },
    },

    data() {
        return {
            formData: {
                name:            '',
                license_plate:   '',
                odometer:        '',
                production_year: '',
                holder_uuid:     '',
                note:            '',
            },
            rules: {
                name:            { required: true, message: this.$t('common.required'), trigger: 'blur' },
                odometer:        { type: 'number', message: this.$t('common.only_numbers'), trigger: 'blur' },
                production_year: [
                    {
                        type:    'number',
                        min:     1980,
                        max:     new Date().getFullYear(),
                        message: `${this.$t('common.min_1980_and_max')} ${new Date().getFullYear()}`,
                        trigger: 'blur',
                    },
                ],
            },
        };
    },

    methods: {
        async getDetails() {
            try {
                this.$wait.start('loading.edit_car');
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/assets/cars/${this.selectedCarId}`);

                this.formData.name = data.name;
                this.formData.license_plate = data.license_plate;
                this.formData.odometer = data.odometer;
                this.formData.production_year = data.production_year;
                this.formData.holder_uuid = data.holder_uuid;
                this.formData.note = data.note;

                this.$wait.end('loading.edit_car');
            } catch (error) {
                this.$wait.end('loading.edit_car');
            }
        },

        async edit() {
            this.$wait.start('loading.edit_car');
            try {
                await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/assets/cars/${this.selectedCarId}`, {
                    name:            this.formData.name,
                    license_plate:   this.formData.license_plate,
                    odometer:        this.formData.odometer,
                    production_year: this.formData.production_year,
                    note:            this.formData.note,
                    holder_uuid:     this.formData.holder_uuid,
                });
                this.$wait.end('loading.edit_car');
                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
                this.$emit('close');
            } catch (error) {
                this.$wait.end('loading.edit_car');
            }
        },

        closeModal() {
            this.$emit('close');
            this.formData = {
                name:            '',
                license_plate:   '',
                odometer:        '',
                production_year: '',
                holder_uuid:     '',
                note:            '',
            };
        },
    },
};
</script>
